import axios from "axios";

// axios.defaults.baseURL = 'https://e-libraryrest.samdu.uz/'
// axios.defaults.baseURL = 'http://localhost:5145/'

//   axios.interceptors.request.use(config => {
//     const token = localStorage.getItem('token')
//     if(token){
//       config.headers.Authorization = `Bearer ${token}`
//     }
//     return config
// })


const axiosInstance = axios.create({
	baseURL: 'https://e-libraryrest.samdu.uz/'
});

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if(token){
    config.headers.Authorization = `Bearer ${token}`
  }
	return config;
});

export default axiosInstance;

// export default axios