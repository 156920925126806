import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './new.css'; // Create this CSS file for styling
import Header from './header';
import Fade from 'react-reveal';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { IoCalendarOutline } from 'react-icons/io5';
import Loader from '../pages/loader';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../api/axios';



const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
};


function SimpleSlider() {
    const [posts, setPosts] = useState([]);
    const [loader, setLoader] = useState(false);
    const { t } = useTranslation();



    useEffect(() => {
        axiosInstance.get(`/api/Post`)
            .then(res => {
                setPosts(res?.data?.result?.items);
                setLoader(true);
            })
            .catch(err => {
                console.log(err);
                setLoader(false)
            })
    }, [])


    return (
        <News className='news mt-3 mb-3'>
            <div className="container">
                <Header title={t('POSTS')} />
                {loader ? 
                    <MySlider {...settings}>
                        {posts && posts?.map((item) => {
                                return (
                                    <Link to={`single-post/${item?.id}`}>
                                        <SliderBox>
                                            <img src={`https://e-libraryrest.samdu.uz/api/file/downloadimagefile/${item?.coverImage?.id}/middle`} alt={`Slide`} />
                                            <Link to={`single-post/${item?.id}`}>
                                                <SliderBoxTop>
                                                    <SliderBoxGrid>
                                                        <Fade left >
                                                            <SliderTimeBox>
                                                                <p><IoCalendarOutline /> {new Date(item?.createdAt).toLocaleDateString()}</p>
                                                            </SliderTimeBox>
                                                            <SliderMainBox>
                                                                <h5>{item?.title}</h5>
                                                            </SliderMainBox>
                                                            <SliderBoxLink>
                                                                <Link to={`single-post/${item?.id}`}>{t('BAT')}</Link>
                                                            </SliderBoxLink>
                                                        </Fade>
                                                    </SliderBoxGrid>
                                                </SliderBoxTop>
                                            </Link>
                                        </SliderBox>
                                    </Link>
                                )
                            })
                        }
                    </MySlider>
                    :
                    <Loader />
                }
            </div>
        </News>
    );
}


const News = styled.div`
    ul {
        list-style: none !important;

        li {
            list-style-type: none !important;
        }
    }

    button {
        display: none !important;
    }
`

const MySlider = styled(Slider)`
    .slick-dots {
        margin-top: 30px !important;
    }
`

const SliderBox = styled.div`
    height: 500px;
    padding: 15px;
    position: relative;
    overflow: hidden;

    &:hover {
        cursor: pointer;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-position: center;
    }
`

const SliderBoxTop = styled.div`
    position: absolute;
    left: 15px;
    bottom: 15px;
    width: calc(100% - 30px);
    height: 250px;
    z-index: 10;
    transition: all 0.5s;
    background-color: #00000063;

    &:hover {
        background-color: #000;
    }
`

const SliderBoxGrid = styled.div`
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 250px;

    div {
        width: 100%;
        z-index: 10;
        padding-right: 15px;
    }
`

const SliderTimeBox = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    overflow: hidden;
    
    p {
        color: #fff;
        opacity: 1 !important;
        line-height: 30px;
    }
`

const SliderMainBox = styled.div`
    padding: 10px;
    padding-top: 0;
    overflow: hidden;

    h5 {
        color: #fff;
        opacity: 1 !important;
        font-size: 15px;
    }

    p {
        color: #fff;
        opacity: 1 !important;
        font-size: 12px;
    }
`

const SliderBoxLink = styled.div`
    padding: 10px;
    overflow: hidden;
    position: absolute;
    bottom: 10px;
`

export default SimpleSlider;