import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import axios from "../api/axios";
import { BookStore } from '../store/books';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './loader';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { CgDanger } from "react-icons/cg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../api/axios';
import fileDownload from 'js-file-download';


const SingleBook = () => {
    const params = useParams();
    const VarBook = useSelector((books) => books.books.book);
    const User = useSelector((user) => user.user.user);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [orderBookBool, setOrderBookBool] = useState(false);
    const [buildings, setBuildings] = useState(null);
    const [buildingID, setBuildingID] = useState('');
    const [orderBookBoolean, setOrderBookBoolean] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        axiosInstance.get(`/api/Book/${params.id}`)
            .then(res => {
                dispatch(BookStore(res.data.result))
                setLoader(true)
            })
            .catch(err => {
                console.log(err);
                setLoader(false);
            })
    }, [])


    useEffect(() => {
        axiosInstance.get(`/api/Book/BookAvailabilityService/${params.id}`)
            .then(res => {
                setBuildings(res?.data?.result);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    function handleOpenBook() {
        window.open(`https://e-libraryrest.samdu.uz/${VarBook?.book_File?.fileUrl}`)
    }

    function handleReadBook() {
        axiosInstance.get(`https://e-libraryrest.samdu.uz/api/file/getbookpart/${VarBook?.id}`)
            .then(res=>{
                fileDownload(res.data,VarBook.title);

            })
        // window.open(`https://e-libraryrest.samdu.uz/api/file/getbookpart/${VarBook?.id}`)
    }


    function handleOrder() {
        setOrderBookBool(!orderBookBool)
        // setBuildingID('')
    }

    useEffect(() => {
        if(buildingID) {
            axiosInstance.post(`/api/Order?BookId=${params.id}&type=1&BuildingId=${buildingID}`)
                .then(res => {
                    toast.success('Muvofiqiyatli kitob buyutma qilindi');
                    setBuildingID('');
                })
                .catch(err => {
                    toast.error('Xatolik yuz berdi!')
                    setBuildingID('');
                })
        }
    }, [orderBookBoolean, orderBookBoolean])



    return (
        <SingleBookPage>
            <div>
                <Navbar />
            </div>
            <SingleBookPageGrid>
                {loader ?
                    <>
                        <SinglePageRight>
                            <SingleBookPageBox>
                                <SingleBookBoxPageGrid>
                                    <SingeBookPageImage>
                                        <h1>
                                            <img src={`https://e-libraryrest.samdu.uz/api/file/downloadimagefile/${VarBook?.coverImage?.id}/middle`} alt='single_book_image' />
                                        </h1>
                                    </SingeBookPageImage>
                                    <SingleBookInformation>
                                        <h4>{VarBook?.title}</h4>
                                        {VarBook && VarBook?.bookAuthors?.map((item, key) => {
                                            return <p key={key} style={{ display: 'inline-block', paddingRight: '10px' }}>{item?.author?.name}</p>
                                        })}
                                        <SinglePageButtons>
                                            {/* <button onClick={handleOpenBook} disabled={User?.accessToken ? false : true}> */}
                                                {/* <a disabled={User?.access_token ? false : true} style={{color: '#000'}} href={`https://e-libraryrest.samdu.uz/${VarBook?.book_File?.fileUrl}`}>Online o'qish</a> */}
                                                {/* {t('ONLINE_READ')} */}
                                            {/* </button> */}
                                            {/* <button onClick={handleOrder} disabled={User?.accessToken ? false : true}>{t('ORDER_BOOK')}</button> */}
                                            <button onClick={handleReadBook} disabled={User?.accessToken ? false : true}>
                                                {/* <a style={{color: '#000'}} href={`https://e-libraryrest.samdu.uz/${VarBook?.book_File?.fileUrl}`} target='_blank' rel="noreferrer">Yuklab olish</a> */}
                                                {t('DOWNLOAD')}
                                            </button>
                                            <button disabled>{t('ADD_FOWARATE')}</button>
                                        </SinglePageButtons>
                                        {User?.accessToken ? '' : 
                                                <DangerBox>
                                                    <div>
                                                        <CgDanger />
                                                    </div>
                                                    <div>
                                                        <p>{t('NO_REGESTER_FUNC')}</p>
                                                    </div>
                                                </DangerBox>
                                            }
                                        <table class="table mt-2 w-75">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">{t('ISBN')}</th>
                                                    <td>{VarBook?.isbn ? VarBook?.isbn : "Aniqlanmadi"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{t('UDK')}</th>
                                                    <td>{VarBook?.udk ? VarBook?.udk : "Aniqlanmadi"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{t('LANGUAGE_BOOK')}</th>
                                                    <td>{VarBook?.language?.name}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{t('PUBLISHER')}</th>
                                                    <td>{VarBook?.publisher}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{t('COPY_COUNT')}</th>
                                                    <td>{VarBook?.book_Copy_Count}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{t('NUMBER_OF_PAGES')}</th>
                                                    <td>{VarBook?.page_Count}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{t('TAGS')}</th>
                                                    <td>{VarBook && VarBook?.bookTags?.map((item) => {
                                                        return <p style={{ display: 'inline-block', margin: '0', paddingRight: '10px' }}>{item?.tag?.name}</p>
                                                    })}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{t('PUBLISHER_DATA')}</th>
                                                    <td>{VarBook?.publish_Date}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <b>{t('ABSTRACT')}: </b>
                                        <p>{VarBook?.description}</p>
                                    </SingleBookInformation>
                                </SingleBookBoxPageGrid>
                            </SingleBookPageBox>
                        </SinglePageRight>
                    </>
                    :
                    <Loader />
                }
            </SingleBookPageGrid>
            {orderBookBool && 
                <OrderBookModalBox>
                    <h5>{t('ORDER_BOOK_SHOW')}</h5>
                    {buildings && buildings.map((item) => {
                        return (
                            <button disabled={item?.inLibraryCount === 0 ? true : false} onClick={() => setBuildingID(item?.buildingId)} style={{backgroundColor: item?.buildingId === buildingID ? 'green' : '#fff', color: item?.buildingId === buildingID ? '#fff' : '#000', boxShadow: '1px 1px 4px 3px rgba(0, 0, 0, 0.1)', marginBottom: '5px'}}>
                                <div disabled={item?.inLibraryCount === 0 ? true : false}>
                                    <h5>{item?.buildingName}</h5>
                                    <p style={{margin: '0', padding: '0'}}>{t('ORDER_BOOK_COUNT')} {item?.inLibraryCount}</p>
                                </div>
                            </button>
                        )
                    })}
                    <button disabled={buildingID ? false : true} onClick={() => {setOrderBookBoolean(!orderBookBoolean); handleOrder()}} style={{backgroundColor: 'green'}}>{t('SAVE')}</button>
                    <button onClick={handleOrder} style={{backgroundColor: 'red'}}>{t('CANCEL')}</button>
                </OrderBookModalBox>
            }
            <div>
                <Footer />
            </div>
        </SingleBookPage>
    )
}


const SingleBookPage = styled.div`
    width: 100%;
    overflow: hidden;
`

const SingleBookPageGrid = styled.div`
    width: 100%;
    min-height: 300px;
    overflow: hidden;
`

const SinglePageRight = styled.div`
    width: 100%;
    padding-top: 62px;
    overflow: hidden;

    &::before {
        content: '';
        position: fixed;
        width: 200%;
        height: 1000px;
        background-color: #ECEFF1;
        transform: rotate(-70deg) translateY(-170%);
        z-index: -10;
        animation: animatSingleBookBefore 1s linear;
    }


    @keyframes animatSingleBookBefore {
        0% {
            transform: rotate(-35deg) translateY(-200%);
        }
        100% {
            transform: rotate(-70deg) translateY(-170%);
        }
    }

    @media (max-width: 576px) {
        padding-left: 40px;
    }
`

const SingleBookPageBox = styled.div`
    width: 100%;
    min-height: calc(100vh - 62px);
    display: flex;
    align-items: center;
    justify-content: center;
`

const SingleBookBoxPageGrid = styled.div`
    width: 1200px;
    min-height: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;

    @media (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const SingeBookPageImage = styled.div`
    width: 100%;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {

        img {
            width: 80%;
        }
    }
`

const SingleBookInformation = styled.div`
    padding: 10px;
`

const SinglePageButtons = styled.div`
    width: 300px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;

    button {
        font-size: 12px;
        width: 100%;
        padding: 5px 8px;
        border-radius: 10px;
        outline: none;
        border: none;
        font-size: 14px;
    }
`

const DangerBox = styled.div`
    width: 400px;
    background-color: red;
    color: #fff;
    display: grid;
    grid-template-columns: 1fr 8fr;
    align-items: center;
    padding: 5px 10px;
    border-radius: 10px;
    margin-top: 10px;


    p {
        margin: 0;
        padding: 0;
    }

    svg {
        font-size: 24px;
    }

    @media (max-width: 576px) {
        width: 300px;
    }
`

const OrderBookModalBox = styled.div`
    width: 350px;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
    min-height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;

    h5 {
        font-size: 16px;
        margin-bottom: 20px;
        margin-top: 10px;
        text-align: center;
    }

    > div {
        width: 100%;
        box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px 0;
        border-radius: 10px;

        h5 {
            font-size: 14px;
            font-weight: bold;
        }
        

        p {
            font-size: 12px;
        }
    }

    button {
        display: block;
        width: 100%;
        border: none;
        border-radius: 5px;
        margin-bottom: 5px;
        padding: 5px 8px;
        color: #fff;
    }
`

export default SingleBook;