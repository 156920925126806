import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import styled from "styled-components";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import Loader from "./loader";
import { Link } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import axiosInstance from "../api/axios";




const Attendance = () => {
    const [inputValue, setInputValue] = useState('');
    const [access, setAccess] = useState(false);
    const [qrCodeValue, setQRCodeValue] = useState('');
    const Role = localStorage.getItem('role');
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [hash, setHash] = useState(null);

    //get hash
    useEffect(() => {
        setLoader(true);
        axiosInstance.get(`/api/user/getattedancehash`)
            .then(res => {
                setHash(res.data);
                setLoader(false);
            })
            .catch(err => {
                console.log(err)
                setLoader(false);
            })
    }, [])
    
    useEffect(() => {
        let Input = document.getElementById('input_attendance');
        if(inputValue) {
            setLoader(true);
            axiosInstance.post(`/api/User/Attendance?query=${inputValue}&buildingId=${localStorage.getItem('building')}&hash=${hash}`)
                .then(res => {
                    toast.success('Davomatingiz qabul qilindi');
                    Input.value = '';
                    setLoader(false);
                })
                .catch(err => {
                    toast.error('Email yoki Hemis ID xato kiritildi!');
                    setLoader(false);
                })
        }
    }, [access])


    useEffect(() => {
        setQRCodeValue(localStorage.getItem('building'))
    }, [])



    return (
        <div>
            <Navbar />
            <AttendanceBox>
                {loader ? <Loader /> : null}
                <div className="container">
                    {Role == 2 ?
                        <>
                            <Header>
                                <h1>{t('ATTENDANCE_HEADER')}</h1>
                            </Header>
                            <AttendanceGrid>
                                <QRCodeBox style={{ height: "auto", margin: "0 auto", maxWidth: '100%', width: "100%" }}>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "200px", width: "200px" }}
                                        value={qrCodeValue}
                                        viewBox={`0 0 256 256`}
                                    />
                                    <p style={{textAlign: 'center'}}>{t('QRCODE')}</p>
                                    
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />

                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "200px", width: "200px" }}
                                        value={'https://play.google.com/store/apps/details?id=com.companyname.samdu_books'}
                                        viewBox={`0 0 256 256`}
                                    />
                                    <p style={{textAlign: 'center'}}>{t('DOWNLOAD_APP')}</p>
                                </QRCodeBox>
                                <div>
                                    <InputBox>
                                        <input id="input_attendance" onChange={(e) => setInputValue(e.target.value)} type="text" placeholder={t('ATTENDANCE_INPUT')} />
                                    </InputBox>
                                    <Functions>
                                        <button onClick={() => setAccess(!access)}>{t('CONFIRM')}</button>
                                    </Functions>
                                </div>
                            </AttendanceGrid>
                        </>
                        :
                        <ErrorPageBox>
                            <h3>Sizga bu sahifaga kirish uchun  ruxsat yuq!</h3>
                        </ErrorPageBox>
                    }
                </div>
            </AttendanceBox>
            <Footer />
        </div>
    )
}


const ErrorPageBox = styled.div`
    margin-top: 100px;
    margin-bottom: 20px;

    h3 {
        text-align: center;
    }
`

const Header = styled.div`
    padding-top: 20px;
    padding-left: 10px;
    
    
    h1 {
        font-size: 28px;
        padding-top: 60px;
    }

    @media (max-width: 576px) {
        h1 {
            font-size: 16px;
        }
    }
`

const AttendanceBox =  styled.div`
    margin-top: 82px;
`

const InputBox = styled.div`
    width: 100%;
    padding: 10px;

    > input {
        width: 100%;
        border: none;
        outline: none;
        box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
        padding: 5px 8px;
        border-radius: 5px;
    }

    @media (max-width: 576px) {
        width: 400px;
    }
`

const Functions = styled.div`
    padding: 10px;
    width: 100%;
    grid-gap: 10px;

    button {
        width: 100%;
        padding: 5px 8px;
        border: none;
        border-radius: 5px;
        background-color: #357a38;
        color: #fff;
    }

    @media (max-width: 576px) {
        select {
            width: 300px;

            option {
                width: 300px !important;
                font-size: 12px;
            }
        }
    }
`


const AttendanceGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 50px;
    margin-bottom: 200px;

    @media (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 20px;
    }

`

const QRCodeBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: '576px') {
        svg {
            width: 100px;
        }
    }

    p {
        margin-top: 10px;
    }
`

const DownlaodApp = styled.div`
    margin-top: 100px;

    a {
        display: flex;
        align-items: center;

        svg {
            color: green;
            margin-right: 10px;
            font-size: 22px;
        }
    }
`


export default Attendance;